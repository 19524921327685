import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { HeaderItemsContainer, HEADER_ITEM_KEYS } from '@confluence/header-items-manager';

import { ContentTools } from './ContentTools';
import type { ContentToolsProps } from './ContentToolsProps';

export class ContentToolsWrapper extends PureComponent<Omit<ContentToolsProps, 'onRender'>> {
	static propTypes = {
		spaceKey: PropTypes.string.isRequired,
		contentId: PropTypes.string.isRequired,
		version: PropTypes.number,
		isFabricPage: PropTypes.bool,
		allowedContentToolsItems: PropTypes.arrayOf(PropTypes.string),
		contentType: PropTypes.string,
		isExternalCollaborator: PropTypes.bool,
		isContentToolsForcedOpen: PropTypes.bool,
		setIsContentToolsForcedOpen: PropTypes.func,
	};

	isItemMarked = false;
	// @ts-expect-error - Type 'null' is not assignable to type '(key: any) => Promise<void>'
	// This error was introduced after upgrading to TypeScript 5
	markItem: HeaderItemsContainer['markItem'] = null;

	buttonRendered = () => {
		if (!this.isItemMarked) {
			this.isItemMarked = true;
			void this.markItem(HEADER_ITEM_KEYS.CONTENT_TOOLS);
		}
	};

	render() {
		return (
			<Subscribe to={[HeaderItemsContainer]}>
				{({ addItem, markItem }: HeaderItemsContainer) => {
					addItem(HEADER_ITEM_KEYS.CONTENT_TOOLS);
					this.markItem = markItem;
					return <ContentTools {...this.props} onRender={this.buttonRendered} />;
				}}
			</Subscribe>
		);
	}
}
