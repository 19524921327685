import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import type { ComponentType, ReactNode } from 'react';
import React, { PureComponent } from 'react';
import { styled } from '@compiled/react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { N40 } from '@atlaskit/theme/colors';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { FlagsProvider } from '@atlaskit/flag';

import { DeleteNestedPagesActionLoader } from '@confluence/bulk-page-actions';
import {
	PRESENTER_MODE_VIEW_EXPERIENCE,
	DELETE_PAGE_EXPERIENCE,
	MOVE_PAGE_DIALOG_LOAD_EXPERIENCE,
	VIEW_PAGE_CONTENT_TOOLS_EXPERIENCE,
	ExperienceStart,
	withExperienceTracker,
} from '@confluence/experience-tracker';
import { monospaceFontFamily } from '@confluence/typography-placeholder';
import {
	LINK_TO_THIS_PAGE_SHORTCUT,
	PRESENTATION_MODE_SHORTCUT,
	GeneralShortcutListener,
	VIEW_ATTACHMENTS_SHORTCUT,
} from '@confluence/shortcuts';
import { WebItemAnalyticsWrapper } from '@confluence/analytics';
import { ATTACHMENTS_ANALYTICS_FROM_ACTIONS_MENU } from '@confluence/attachments';
import { ConfluenceEdition } from '@confluence/change-edition';
import { CopyPageTreeDialog } from '@confluence/copy-page-tree-dialog';
import { DialogsStateContainer } from '@confluence/dialogs';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { ForgeKeyboardShortcut, ForgeKeyboardShortcutVisualizer } from '@confluence/forge-ui';
import { LinkToThisPageDialog } from '@confluence/link-to-this-page-dialog';
import { ConvertToLivePageDialog } from '@confluence/live-pages-features';
import {
	SPACE_OVERVIEW,
	VIEW_PAGE,
	CUSTOM_CONTENTS_ON_PAGE_LIST,
	CONTENT_HISTORY,
} from '@confluence/named-routes';
import { MovePageDialog } from '@confluence/page-tree/entry-points/MovePageDialog';
import { AnalyticsPluginWrapper } from '@confluence/plugin-wrappers';
import { LazyPresenterModeContextConsumer } from '@confluence/presentation-mode';
import { SSRActionLoadingSpinner } from '@confluence/ssr-utilities';
import { useSpaceId } from '@confluence/space-utils';
import {
	PublishPageAsBlogDialog,
	PublishPageAsBlogSources,
} from '@confluence/publish-page-as-blog';
import { RevertEditorDialog } from '@confluence/editor-conversion/entry-points/RevertEditorDialog';
import {
	ResolvedInlineCommentsCounterLoader,
	ResolvedInlineCommentsDialogLoader,
	ResolvedInlineCommentsQueryRendererLoader,
} from '@confluence/resolved-inline-comments-dialog';
import { RestrictionsDialogMenuPropsProvider } from '@confluence/restrictions';
import { PublicLinksExportMenuEntryPoint } from '@confluence/public-links/entry-points/PublicLinksExportMenuEntryPoint';
import type { RoutesContextType } from '@confluence/route-manager';
import { RoutesContext } from '@confluence/route-manager';
import { SessionData, withSessionData } from '@confluence/session-data';
import { ANALYTICS_ADDON_ACTIONS_MENU_ID, CONNECT_APP_PREFIX_KEY } from '@confluence/web-item';
import {
	type FormattedWebItem,
	WebItemLocation,
	type WebItemLocationChildrenFnWithLoading,
} from '@confluence/web-item-location';
import { LoadableLazy } from '@confluence/loadable';
import {
	openMenuClick,
	simulateItemClick,
} from '@confluence/legacy-keyboard-shortcut-helpers/entry-points/legacyKeyboardShortcutHelpers';
import { MoveBlogModalLoader } from '@confluence/move-blog-modal';
import {
	RevertAutoConvertEvent,
	AutoConvertActionSubject,
	sendEditorConversionAnalyticsPageEvent,
} from '@confluence/editor-conversion';
import {
	ContentOwnershipContextConsumer,
	CONTENT_OWNERSHIP_WEB_ITEM_ID,
	ContentOwnerPopup,
} from '@confluence/content-ownership';
import { getSystemPreferredColorScheme } from '@confluence/theming-utils';
import { withThemingState } from '@confluence/theming/entry-points/withThemingState';

import {
	ArchivePageItem,
	ACTION_ARCHIVE_PAGE_LINK,
	ACTION_RESTORE_PAGE_LINK,
} from './ArchivePageItem';
import {
	DELETE_PAGE_LINK,
	ACTION_MOVE_BLOG_DIALOG,
	REVERT_EDITOR_LINK,
	PAGE_HISTORY_LINK,
} from './constants';
import { ContentToolsComponent, type ContentToolsComponentProps } from './ContentToolsComponent';
import { ContentToolsButton } from './ContentToolsButton';
import type {
	ContentToolsClassProps,
	ContentToolsClassState,
	ContentToolsProps,
} from './ContentToolsProps';
import { ForgeContentTools } from './ForgeContentTools';
import { LazyContentToolsItem, LazyContentToolsItemWithPlaceholder } from './LazyContentToolsItem';
import { EndOfPageRecMenuItem } from './EndOfPageRecMenuItem';
import { ConvertToFolderItem } from './ConvertToFolderItem';

const TOOLS_MENU_LOCATION = 'system.content.action';
const ACTION_MENU_RESOLVED_INLINE_COMMENTS = 'view-resolved-comments';
const ACTION_MENU_PDF_EXPORT = 'action-export-pdf-link';
const ACTION_MENU_WORD_EXPORT = 'action-export-word-link';
export const ACTION_MENU_PAGE_RESTRICTIONS = 'action-page-permissions-link';
export const ACTION_MOVE_PAGE_DIALOG = 'action-move-page-dialog-link';
export const ACTION_EXPORT_WEB_ITEM = 'action-export-web-item';
export const ACTION_PUBLISH_PAGE_AS_BLOG_DIALOG = 'action-convert-page-to-blog';
export const ACTION_CONVERT_PAGE_TO_FOLDER_DIALOG = 'action-convert-page-to-folder';
export const VIEW_ATTACHMENTS_LINK = 'view-attachments-link';
export const VIEW_CUSTOM_CONTENTS_LINK = 'view-custom-contents-link';
const LINK_TO_THIS_PAGE = 'link-to-page-link';
export const LINK_TO_VIEW_SOURCE = 'action-view-source-link';
const LINK_TO_VIEW_HIERARCHY = 'view-in-hierarchy-link';
const LINK_TO_VIEW_STORAGE = 'action-view-storage-link';
const VIEW_CONTENT_API = 'content-api';
const IMPORT_WORD_DOC = 'import-word-doc';
const EXPORT = 'export';
const ADVANCED = 'advanced';
export const THIRD_PARTY = 'third-party';
const OTHERS = 'others';
const END_OF_PAGE_RECOMMENDATION_MENU = 'end-of-page-recommendation-menu';
const ATTACHMENTS = 'attachments';
export const SWITCH_TO_LIVE_EDIT_WEB_ITEM = 'action-switch-to-live-edit';
const CONTENT_OWNER = 'content-owner';

export const COPY_PAGE_LINK = 'action-copy-page-link';
const PAGE_INFO_LINK = 'view-page-info-link';
export const DELETE_BUTTON_ANALYTICS_SOURCE = 'contentToolsItem';
export const REVERT_BUTTON_ANALYTICS_SOURCE = 'contentToolsItem';
export const PRESENTATION_MODE = 'presentation-mode';

// NOTE: these same styles are used in MoreActions.tsx in @confluence/content-types-header
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelContainer = styled.div({
	flex: 2,
	marginRight: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShortcutContainer = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontFamily: monospaceFontFamily,
	backgroundColor: token('color.background.inverse.subtle', N40),
	borderRadius: '3px',
	padding: `0 ${token('space.050', '4px')}`,
	fontWeight: 500,
});

const LazyContentToolsItemForCopy = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ContentToolsItemForCopy" */ './ContentToolsItemForCopy'
			)
		).ContentToolsItemForCopy,
});

const LazyContentToolsItemForMove = LazyContentToolsItem;

const i18n = defineMessages({
	filesLabel: {
		defaultMessage: 'Files',
		id: 'content-tools.files.nested.section.heading.files',
		description:
			'The text for Files that display in the menu dropdown that group Files and Custom Contents',
	},
	customContentsLabel: {
		defaultMessage: 'Custom Contents',
		id: 'content-tools.files.nested.section.heading.custom-contents',
		description:
			'The text for Custom Contents that display in the menu dropdown that group Files and Custom Contents',
	},
});

class ContentToolsClass extends PureComponent<ContentToolsClassProps, ContentToolsClassState> {
	context: any;
	static propTypes = {
		spaceKey: PropTypes.string.isRequired,
		contentId: PropTypes.string.isRequired,
		createAnalyticsEvent: PropTypes.func.isRequired,
		version: PropTypes.number,
		onRender: PropTypes.func,
		intl: PropTypes.object,
		experienceTracker: PropTypes.object.isRequired,
		isFabricPage: PropTypes.bool,
		/**
		 * Used by Embedded Pages to specify the identifiers of allowed
		 * `ContentToolsItem`s. An emtpy array means that no `ContentToolsItem`s are
		 * allowed and, consequently, `ContentTools` will not render a menu at all.
		 * For Confluence all `ContentToolItem`s will be allowed!
		 */
		allowedContentToolsItems: PropTypes.arrayOf(PropTypes.string),
		contentType: PropTypes.string,
		isExternalCollaborator: PropTypes.bool,
		theme: PropTypes.shape({
			dark: PropTypes.string,
			light: PropTypes.string,
			colorMode: PropTypes.string,
		}),
		isContentToolsForcedOpen: PropTypes.bool,
		setIsContentToolsForcedOpen: PropTypes.func,
	};

	contentToolsComponentRef = React.createRef<{ closeDialog: () => void }>();

	splitInSections = (webItems: ReadonlyArray<FormattedWebItem>) =>
		webItems ? groupBy(webItems, 'section') : {};

	makePublishPageAsBlogDialogOpener = (dialogs) => () => {
		const { contentId, createAnalyticsEvent, spaceKey } = this.props;

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'publishPageAsBlog',
				source: 'contentToolsItem',
			},
		}).fire();
		dialogs.showDialog(PublishPageAsBlogDialog, {
			contentId,
			spaceKey,
			source: PublishPageAsBlogSources.ViewPage,
		});
	};

	makePresenterModeOpener = (enterPresenterMode) => () => {
		const { contentId, createAnalyticsEvent, experienceTracker, spaceId, theme } = this.props;

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'present',
				source: 'moreActions',
				objectId: contentId,
				containerId: spaceId,
				attributes: {
					currentThemePreference: theme,
					OStheme: getSystemPreferredColorScheme(),
				},
			},
		}).fire();
		experienceTracker.start({
			name: PRESENTER_MODE_VIEW_EXPERIENCE,
		});
		enterPresenterMode();
	};

	makeChangeOwnershipOpener = (openChangeOwnership) => () => {
		const { contentId, createAnalyticsEvent } = this.props;

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'page.owner',
				source: 'moreActions',
				objectId: contentId,
			},
		}).fire();
		openChangeOwnership();
	};

	makeConvertToLivePageDialogOpener = (dialogs: DialogsStateContainer) => () => {
		const { contentId, createAnalyticsEvent, spaceId } = this.props;

		const handleConfirm = () => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'convertedToLivePage',
					actionSubject: 'page',
					source: 'viewPageScreen',
					objectId: contentId,
					objectType: 'page',
					containerId: spaceId,
					containerType: 'space',
				},
			}).fire();
		};

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'switchToLiveEdit',
				source: 'viewPageScreen',
				objectId: contentId,
				objectType: 'page',
				containerId: spaceId,
				containerType: 'space',
			},
		}).fire();

		dialogs.showModal(ConvertToLivePageDialog, {
			contentId,
			onConfirm: handleConfirm,
			adfOrigin: 'RENDERER',
		});
	};

	makeDeletePageDialogOpener = (dialogs, edition) => () => {
		const { contentId, contentType, createAnalyticsEvent, experienceTracker } = this.props;

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'deletePage',
				source: DELETE_BUTTON_ANALYTICS_SOURCE,
				attributes: {
					showArchiveUpsell: edition === ConfluenceEdition.FREE,
				},
			},
		}).fire();
		experienceTracker.start({
			name: DELETE_PAGE_EXPERIENCE,
		});
		dialogs.showModal(DeleteNestedPagesActionLoader, {
			contentId,
			source: 'contentToolsItem',
			shouldRedirectOnSuccess: true,
			contentType,
		});
	};

	makeRevertEditorDialogOpener = (dialogs) => async () => {
		const { contentId, createAnalyticsEvent } = this.props;

		sendEditorConversionAnalyticsPageEvent(
			createAnalyticsEvent,
			{
				pageAction: RevertAutoConvertEvent.DIALOG_OPEN,
				source: 'ViewPageAutoConversion',
			},
			AutoConvertActionSubject.AUTO_CONVERSION,
		);
		dialogs.showDialog(RevertEditorDialog, { contentId });
	};

	makeLinkToThisPageDialogOpener = (dialogs) => () => {
		const { contentId } = this.props;

		dialogs.showDialog(LinkToThisPageDialog, { contentId });
	};

	makeCopyPageTreeDialogOpener = (dialogs) => () => {
		const { contentId, spaceKey } = this.props;

		dialogs.showDialog(CopyPageTreeDialog, { contentId, spaceKey });
	};

	makeInlineCommentsDialogOpener = (dialogs) => () => {
		const { contentId, spaceKey } = this.props;

		dialogs.showDialog(ResolvedInlineCommentsDialogLoader, {
			spaceKey,
			contentId,
			context: 'renderer',
		});
	};

	makeModernizedMovePageDialogOpener = (dialogs) => () => {
		const { contentId, createAnalyticsEvent, experienceTracker, spaceKey } = this.props;

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'move',
				source: 'contentToolsItem',
				objectType: 'page',
			},
		}).fire();
		experienceTracker.start({
			name: MOVE_PAGE_DIALOG_LOAD_EXPERIENCE,
		});
		dialogs.showDialog(MovePageDialog, {
			spaceKey,
			contentId,
			referrer: 'view-page-actions-menu',
		});
	};

	makeModernizedMoveBlogModalOpener = (dialogs) => () => {
		const { contentId, createAnalyticsEvent, spaceKey } = this.props;

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'move',
				source: 'contentToolsItem',
				objectType: 'blog',
			},
		}).fire();
		dialogs.showModal(MoveBlogModalLoader, {
			currentSpaceKey: spaceKey,
			blogPostId: contentId,
			isEditMode: false,
			isUnpublishedDraft: false,
			referrer: 'view-page-actions-menu',
		});
	};

	navigateToAttachments = (push, url) => () => {
		push(url);
	};

	closeDropdownMenu = () => {
		this.setState({
			nestedPopup: undefined,
			isDropdownMenuOpen: false,
		});
	};
	getSectionComponent = (
		menuGroupings: ReadonlyArray<FormattedWebItem>,
		section: NonNullable<ContentToolsClassState['nestedPopup']>,
		dialogs: DialogsStateContainer,
		contentId,
		triggerLabel,
	) => {
		return (
			<DropdownMenu
				key={`${section}-dropdown`}
				placement="auto-start"
				isOpen={this.state?.nestedPopup === section}
				onOpenChange={({ isOpen }) => {
					if (isOpen) {
						this.setState({
							nestedPopup: section,
						});
					} else {
						this.setState({
							nestedPopup: undefined,
						});
					}
				}}
				trigger={({ triggerRef, ...triggerProps }) => (
					<DropdownItem
						{...triggerProps}
						ref={triggerRef}
						testId={`${section}-button`}
						elemAfter={<ChevronRightIcon label="" />}
					>
						{section === EXPORT &&
							// when backend supplies a label, use that instead.
							(triggerLabel ? (
								<span>{triggerLabel}</span>
							) : (
								<FormattedMessage
									defaultMessage="Export"
									id="content-tools.export.nested.section.heading"
									description="The text that display in the menu dropdown that group Export to PDF and Export to Word"
								/>
							))}
						{section === ADVANCED && (
							<FormattedMessage
								defaultMessage="Advanced details"
								id="content-tools.advanced.nested.section.heading"
								description="The text that display in the menu dropdown that group Page Information, Page Information, View Source and View Content API"
							/>
						)}
						{section === ATTACHMENTS && (
							<FormattedMessage
								defaultMessage="Attachments"
								id="content-tools.attachments.nested.section.heading"
								description="The text that display in the menu dropdown that group Files and Custom Contents"
							/>
						)}
					</DropdownItem>
				)}
			>
				<DropdownItemGroup>
					{menuGroupings.filter(Boolean).map((sectionItem) => {
						if (sectionItem.id === LINK_TO_THIS_PAGE) {
							sectionItem.url = '';
							sectionItem.urlWithoutContextPath = '';
							// analytics to monitor the roll out of removal of Link to this page from page actions menu
							return (
								<WebItemAnalyticsWrapper
									key={sectionItem.completeKey}
									analyticsData={{
										type: 'sendUIEvent',
										data: {
											action: 'clicked',
											actionSubject: 'LinkToThisPageButton',
											source: 'viewPageScreen',
											attributes: {
												triggeredFrom: 'actionsMenu',
											},
										},
									}}
								>
									<LazyContentToolsItem
										key={sectionItem.completeKey}
										{...sectionItem}
										onClick={this.makeLinkToThisPageDialogOpener(dialogs)}
									/>
								</WebItemAnalyticsWrapper>
							);
						} else if (sectionItem.id === LINK_TO_VIEW_HIERARCHY) {
							// analytics to monitor the roll out of removal of View In Hierarchy from page actions menu
							return (
								<WebItemAnalyticsWrapper
									key={sectionItem.completeKey}
									analyticsData={{
										type: 'sendUIEvent',
										data: {
											action: 'clicked',
											actionSubject: 'ViewInHierarchyButton',
											source: 'viewPageScreen',
											attributes: {
												triggeredFrom: 'actionsMenu',
											},
										},
									}}
								>
									<LazyContentToolsItem key={sectionItem.completeKey} {...sectionItem} />
								</WebItemAnalyticsWrapper>
							);
						} else if (sectionItem.id === END_OF_PAGE_RECOMMENDATION_MENU) {
							return (
								<EndOfPageRecMenuItem
									key={sectionItem.completeKey}
									entityId={contentId}
									entityType={this.props.contentType}
									spaceKey={this.props.spaceKey}
									contentToolsRef={this.contentToolsComponentRef.current}
								/>
							);
						} else if (sectionItem.id === VIEW_ATTACHMENTS_LINK) {
							// Renaming inner Attachments-label to "Files"
							const label = this.props.intl.formatMessage(i18n.filesLabel);
							const attachmentsCountStr = sectionItem?.label?.match(/\d+/g)?.[0] || '';
							const newLabel = `${label} (${attachmentsCountStr})`;

							return (
								<WebItemAnalyticsWrapper
									key={sectionItem.completeKey}
									analyticsData={ATTACHMENTS_ANALYTICS_FROM_ACTIONS_MENU}
								>
									<LazyContentToolsItem {...sectionItem} label={newLabel} />
								</WebItemAnalyticsWrapper>
							);
						} else if (sectionItem.id === VIEW_CUSTOM_CONTENTS_LINK) {
							// TODO: make a call (gql.content() when ready) to get numberOfCustomContentTypes
							const label = this.props.intl.formatMessage(i18n.customContentsLabel);
							const numStr = '';
							const newLabel = `${label} (${numStr})`;

							return (
								<WebItemAnalyticsWrapper
									key={sectionItem.completeKey}
									analyticsData={{
										type: 'sendUIEvent',
										data: {
											action: 'clicked',
											actionSubject: 'ViewCustomContentsButton',
											source: 'contentToolMenu',
											attributes: {
												triggeredFrom: 'actionsMenu',
											},
										},
									}}
								>
									<LazyContentToolsItem {...sectionItem} label={newLabel} />
								</WebItemAnalyticsWrapper>
							);
						}

						return <LazyContentToolsItem key={sectionItem.completeKey} {...sectionItem} />;
					})}
					{section === EXPORT && (
						<PublicLinksExportMenuEntryPoint
							closeDropdownMenu={this.closeDropdownMenu}
							contentId={contentId}
						/>
					)}
				</DropdownItemGroup>
			</DropdownMenu>
		);
	};

	createMenuItems(
		webItems: ReadonlyArray<FormattedWebItem>,
		webSectionsKeys,
		dialogs,
		isSpaceOverview = false,
		isViewPage = true,
		isHistoryPage = false,
	) {
		const { contentId, isFabricPage, spaceKey, sessionData } = this.props;

		const sections = this.splitInSections(webItems);
		const itemSections: Array<NonNullable<ReactNode>> = [];
		const keyboardShortcuts: ContentToolsComponentProps['keyboardShortcuts'] = []; //need support for web items
		const exportMenuGroupings: Array<FormattedWebItem> = [];
		const advancedMenuGroupings: Array<FormattedWebItem> = [];
		const thirdPartyMenuGroupings: Array<FormattedWebItem> = [];
		const attachmentMenuGroupings: Array<FormattedWebItem> = [];
		const commonProps = {};
		let revertEditorComponent;

		const isLivePagesEnabled = sessionData?.featureFlags['confluence.frontend.livepages.enable'];
		const isContentOwnerSupportedForAllTypes =
			sessionData?.featureFlags['confluence.frontend.content.owner.support.all.types'];

		// First iteration: popuplate menu groupings arrays.
		(webSectionsKeys || Object.keys(sections)).forEach((key) => {
			(sections[key] || []).forEach((sectionItem): ReactNode => {
				switch (sectionItem['subSection']) {
					case EXPORT:
						exportMenuGroupings.push(sectionItem);
						return;
					case ADVANCED:
						advancedMenuGroupings.push(sectionItem);
						return;
					case THIRD_PARTY:
						// Necessary for some macros/apps especially for third party Connect macro/apps to render iframes/modals
						// for some reason ¯\_(ツ)_/¯
						sectionItem['className'] = sectionItem.styleClass;
						thirdPartyMenuGroupings.push(sectionItem);
						return;
				}
			});
		});

		// Second iteration: generate JSX to render
		(webSectionsKeys || Object.keys(sections)).forEach((key) => {
			const sectionItemsComponent = (sections[key] || [])
				.map((sectionItem): ReactNode => {
					// Skip over any `sectionItem`s that were already adding to a MenuGrouping above.
					switch (sectionItem['subSection']) {
						case EXPORT:
							return null;
						case ADVANCED:
							return null;
						case THIRD_PARTY:
							return null;
					}
					if (sectionItem.id === REVERT_EDITOR_LINK) {
						//need to check if it is available and then push to the array at the end to put it at the bottom after third party section.
						revertEditorComponent = (
							<ErrorBoundary attribution={Attribution.BACKBONE} key={sectionItem.completeKey}>
								<LazyContentToolsItem
									key={sectionItem.completeKey}
									{...sectionItem}
									{...commonProps}
									url={undefined}
									onClick={this.makeRevertEditorDialogOpener(dialogs)}
								/>
							</ErrorBoundary>
						);
						return null;
					}

					if (sectionItem.id === PAGE_HISTORY_LINK) {
						// Link component ignores query params so it still transitions instead of going to Next
						return (
							<LazyContentToolsItem
								{...commonProps}
								id={sectionItem.id}
								label={sectionItem.label}
								url={sectionItem.url}
								key={sectionItem.completeKey}
							/>
						);
					}

					if (sectionItem.id === ANALYTICS_ADDON_ACTIONS_MENU_ID) {
						return (
							<AnalyticsPluginWrapper webItemId={sectionItem.id} key={sectionItem.completeKey}>
								<LazyContentToolsItem {...sectionItem} {...commonProps} />
							</AnalyticsPluginWrapper>
						);
					}

					if (sectionItem.id === ACTION_MENU_RESOLVED_INLINE_COMMENTS) {
						return (
							<LazyContentToolsItem
								{...commonProps}
								id="bm-resolved-inline-comments"
								key={sectionItem.completeKey}
								label={
									<Container>
										<LabelContainer>
											<span>{sectionItem.label} </span>
										</LabelContainer>
										<ResolvedInlineCommentsCounterLoader contentId={contentId} />
									</Container>
								}
								onClick={this.makeInlineCommentsDialogOpener(dialogs)}
							/>
						);
					}

					if (sectionItem.id === ACTION_MENU_PAGE_RESTRICTIONS) {
						if (isSpaceOverview && !this.props.isExternalCollaborator) {
							return (
								<ErrorBoundary
									attribution={Attribution.ADMIN_EXPERIENCE}
									key={sectionItem.completeKey}
								>
									<RestrictionsDialogMenuPropsProvider contentId={contentId}>
										{(itemProps) => <LazyContentToolsItem {...itemProps} {...commonProps} />}
									</RestrictionsDialogMenuPropsProvider>
								</ErrorBoundary>
							);
						}
						return null;
					}

					if (sectionItem.id === LINK_TO_THIS_PAGE) {
						sectionItem.url = '';
						sectionItem.urlWithoutContextPath = '';
						keyboardShortcuts.push({
							accelerator: LINK_TO_THIS_PAGE_SHORTCUT,
							itemId: sectionItem.id,
						});

						return (
							<LazyContentToolsItem
								key={sectionItem.completeKey}
								{...sectionItem}
								{...commonProps}
								onClick={this.makeLinkToThisPageDialogOpener(dialogs)}
							/>
						);
					}

					if (sectionItem.id === COPY_PAGE_LINK) {
						sectionItem.url = '';
						sectionItem.urlWithoutContextPath = '';

						return (
							<LazyContentToolsItemForCopy
								key={sectionItem.completeKey}
								{...sectionItem}
								{...commonProps}
								onClick={this.makeCopyPageTreeDialogOpener(dialogs)}
							/>
						);
					}

					if (sectionItem.id === PRESENTATION_MODE) {
						if (!isSpaceOverview) {
							sectionItem.url = '';
							sectionItem.urlWithoutContextPath = '';
							keyboardShortcuts.push({
								accelerator: PRESENTATION_MODE_SHORTCUT,
								itemId: sectionItem.id,
							});

							return (
								<ErrorBoundary attribution={Attribution.COMMENTS} key={sectionItem.completeKey}>
									<LazyPresenterModeContextConsumer
										key={`presenter-mode-menu-item-${sectionItem.completeKey}`}
									>
										{(enterPresenterMode) => (
											<LazyContentToolsItemWithPlaceholder
												key={sectionItem.completeKey}
												{...sectionItem}
												{...commonProps}
												onClick={this.makePresenterModeOpener(enterPresenterMode)}
												label={
													<Container>
														<LabelContainer>
															<span>{sectionItem.label}</span>
														</LabelContainer>
														<ShortcutContainer>r</ShortcutContainer>
													</Container>
												}
											/>
										)}
									</LazyPresenterModeContextConsumer>
								</ErrorBoundary>
							);
						}
						return null;
					}

					if (sectionItem.id === CONTENT_OWNERSHIP_WEB_ITEM_ID) {
						if (isViewPage) {
							sectionItem.url = '';
							sectionItem.urlWithoutContextPath = '';

							const handleOnClick = () => {
								if (this.state?.nestedPopup === CONTENT_OWNER) {
									this.setState({
										nestedPopup: undefined,
									});
								} else {
									this.setState({
										nestedPopup: CONTENT_OWNER,
									});
								}
							};

							const handleClosePopup = () => {
								this.setState({
									nestedPopup: undefined,
								});
							};

							return (
								<ErrorBoundary attribution={Attribution.BACKBONE} key={sectionItem.completeKey}>
									{isContentOwnerSupportedForAllTypes ? (
										<ContentOwnerPopup
											key={`container-${sectionItem.id}`}
											itemId={sectionItem.id}
											contentId={contentId}
											itemLabel={sectionItem.label}
											onCloseParent={this.closeDropdownMenu}
											onClosePopup={handleClosePopup}
											isOpen={this.state?.nestedPopup === CONTENT_OWNER}
											onClick={handleOnClick}
										/>
									) : (
										<ContentOwnershipContextConsumer>
											{({ openModal }) => (
												<LazyContentToolsItem
													key={sectionItem.completeKey}
													{...sectionItem}
													{...commonProps}
													onClick={this.makeChangeOwnershipOpener(openModal)}
												/>
											)}
										</ContentOwnershipContextConsumer>
									)}
								</ErrorBoundary>
							);
						}
						return null;
					}

					if (sectionItem.id === ACTION_PUBLISH_PAGE_AS_BLOG_DIALOG) {
						if (isViewPage && isFabricPage) {
							sectionItem.url = '';
							sectionItem.urlWithoutContextPath = '';

							return (
								<LazyContentToolsItem
									key={sectionItem.completeKey}
									{...sectionItem}
									{...commonProps}
									onClick={this.makePublishPageAsBlogDialogOpener(dialogs)}
								/>
							);
						}
						return null;
					}

					if (sectionItem.id === ACTION_CONVERT_PAGE_TO_FOLDER_DIALOG) {
						if (isViewPage) {
							sectionItem.url = '';
							sectionItem.urlWithoutContextPath = '';

							return (
								<ConvertToFolderItem
									key={sectionItem.completeKey}
									sectionItem={sectionItem}
									contentId={contentId}
									spaceKey={spaceKey}
									dialogs={dialogs}
									{...commonProps}
								/>
							);
						}
						return null;
					}

					if (sectionItem.id === VIEW_ATTACHMENTS_LINK) {
						const sectionItemForCustomContents = {
							id: VIEW_CUSTOM_CONTENTS_LINK,
							label: 'Custom Contents',
							url: CUSTOM_CONTENTS_ON_PAGE_LIST.toUrl({
								contentId,
								spaceKey,
							}),
							accessKey: null,
							completeKey: 'confluence.content.action.menu.new:view-custom-contents',
							hasCondition: true,
							icon: null,
							subSection: ATTACHMENTS,
						} as any;

						attachmentMenuGroupings.push(sectionItem);
						attachmentMenuGroupings.push(sectionItemForCustomContents);

						return this.getSectionComponent(
							attachmentMenuGroupings,
							ATTACHMENTS,
							dialogs,
							contentId,
							sectionItem.label,
						);
					}

					sectionItem['className'] = sectionItem.styleClass;

					if (sectionItem.id === DELETE_PAGE_LINK) {
						return (
							<ErrorBoundary
								attribution={Attribution.ADMIN_EXPERIENCE}
								key={sectionItem.completeKey}
							>
								<SessionData>
									{({ edition }) => (
										<LazyContentToolsItem
											key={sectionItem.completeKey}
											{...sectionItem}
											{...commonProps}
											url={undefined}
											onClick={this.makeDeletePageDialogOpener(dialogs, edition)}
										/>
									)}
								</SessionData>
							</ErrorBoundary>
						);
					}

					if (
						sectionItem.id === ACTION_ARCHIVE_PAGE_LINK ||
						sectionItem.id === ACTION_RESTORE_PAGE_LINK
					) {
						return (
							<ArchivePageItem
								key={sectionItem.completeKey}
								{...commonProps}
								contentId={contentId}
								sectionItem={sectionItem}
							/>
						);
					}

					if (
						sectionItem.id === ACTION_MOVE_BLOG_DIALOG ||
						sectionItem.id === ACTION_MOVE_PAGE_DIALOG
					) {
						const makeDialogOpener: keyof ContentToolsClass =
							sectionItem.id === ACTION_MOVE_BLOG_DIALOG
								? 'makeModernizedMoveBlogModalOpener'
								: 'makeModernizedMovePageDialogOpener';

						sectionItem.url = '';
						sectionItem.urlWithoutContextPath = '';
						sectionItem.id = `${sectionItem.id}-modernized`;

						return (
							<LazyContentToolsItemForMove
								key={sectionItem.completeKey}
								{...sectionItem}
								{...commonProps}
								onClick={this[makeDialogOpener](dialogs)}
							/>
						);
					}

					if (sectionItem.id === SWITCH_TO_LIVE_EDIT_WEB_ITEM) {
						if (isLivePagesEnabled && !isHistoryPage) {
							sectionItem.url = '';
							sectionItem.urlWithoutContextPath = '';
							return (
								<LazyContentToolsItem
									key={sectionItem.completeKey}
									{...sectionItem}
									{...commonProps}
									onClick={this.makeConvertToLivePageDialogOpener(dialogs)}
								/>
							);
						}
						return null;
					}

					if (sectionItem.id === ACTION_EXPORT_WEB_ITEM) {
						if (key === 'system.content.action/modify' && !this.props.allowedContentToolsItems) {
							return this.getSectionComponent(
								exportMenuGroupings,
								EXPORT,
								dialogs,
								contentId,
								sectionItem.label,
							);
						}
						return null;
					}

					return <LazyContentToolsItem key={sectionItem.completeKey} {...sectionItem} />;
				})
				.filter((item): item is NonNullable<ReactNode> => !!item);

			if (key === 'system.content.action/primary' && !this.props.allowedContentToolsItems) {
				const inlineCommentsMenuItemIndex = sectionItemsComponent.findIndex(
					(sectionItem) =>
						sectionItem?.['key'] ===
						'com.atlassian.confluence.plugins.confluence-inline-comments:view-resolved-comments-new',
				);

				const advancedSectionComponent = this.getSectionComponent(
					advancedMenuGroupings,
					ADVANCED,
					dialogs,
					contentId,
					undefined,
				);
				sectionItemsComponent.splice(inlineCommentsMenuItemIndex + 1, 0, advancedSectionComponent);
			}

			if (sectionItemsComponent.length > 0) {
				itemSections.push(sectionItemsComponent);
			}
		});

		if (!this.props.allowedContentToolsItems) {
			// reorder Slack Notification to the beginning of the list
			const slackIntegrationMenuItemIndex = thirdPartyMenuGroupings.findIndex(
				(item) => item.id === 'confluence-chats-integration__page-config-dialog-trigger',
			);
			const slackIntegrationMenuItem = thirdPartyMenuGroupings.splice(
				slackIntegrationMenuItemIndex,
				1,
			)[0];
			thirdPartyMenuGroupings.splice(0, 0, slackIntegrationMenuItem);

			const thirdPartyMenuGroupingsComponent = thirdPartyMenuGroupings
				.filter(Boolean)
				.map((item) => (
					<LazyContentToolsItemWithPlaceholder key={item.completeKey} {...item} {...commonProps} />
				));

			if (thirdPartyMenuGroupingsComponent.length > 0) {
				itemSections.push(thirdPartyMenuGroupingsComponent);
			}
		}

		if (revertEditorComponent) {
			itemSections.push(revertEditorComponent);
		}

		return { itemSections, keyboardShortcuts };
	}

	/**
	 * The resolved inline comment dialog can be launched from any link a resolved inline comment
	 * i.e. /wiki/spaces/CFE/pages/17850892304?focusedCommentId=17860860011#comment-17860860011
	 * The query renderer here will launch the dialog if there is a matching focusedCommentId query
	 */
	renderResolvedInlineCommentsQueryRendererLoader = ({ getQueryParams }: RoutesContextType) => {
		const { focusedCommentId } = getQueryParams();
		return focusedCommentId ? (
			<ResolvedInlineCommentsQueryRendererLoader contentId={this.props.contentId} />
		) : null;
	};

	groupWebItemsBySubSection = (webItems: ReadonlyArray<FormattedWebItem>) => {
		webItems.forEach((webItem) => {
			if ([ACTION_MENU_WORD_EXPORT, ACTION_MENU_PDF_EXPORT].indexOf(webItem.id!) !== -1) {
				webItem['subSection'] = EXPORT;
			} else if (
				[
					LINK_TO_THIS_PAGE,
					PAGE_INFO_LINK,
					LINK_TO_VIEW_SOURCE,
					LINK_TO_VIEW_HIERARCHY,
					LINK_TO_VIEW_STORAGE,
					VIEW_CONTENT_API,
					IMPORT_WORD_DOC,
					END_OF_PAGE_RECOMMENDATION_MENU,
				].indexOf(webItem.id!) !== -1
			) {
				webItem['subSection'] = ADVANCED;
			} else if (
				(webItem.completeKey ?? '').indexOf(CONNECT_APP_PREFIX_KEY) !== -1 &&
				webItem.id !== ANALYTICS_ADDON_ACTIONS_MENU_ID
			) {
				webItem['subSection'] = THIRD_PARTY;
			} else if ([ATTACHMENTS, VIEW_CUSTOM_CONTENTS_LINK].indexOf(webItem.id!) !== -1) {
				webItem['subSection'] = ATTACHMENTS;
			} else {
				webItem['subSection'] = OTHERS;
			}
		});
	};

	getAllowedWebItems = (webItems: ReadonlyArray<FormattedWebItem>) => {
		if (this.props.allowedContentToolsItems) {
			return webItems.filter((sectionItem) =>
				this.props.allowedContentToolsItems!.includes(sectionItem.id!),
			);
		}
		return webItems;
	};

	addEndofPageRecommendationToWebItems = (webItems) => [
		...webItems,
		{
			accessKey: null,
			completeKey: END_OF_PAGE_RECOMMENDATION_MENU,
			hasCondition: true,
			icon: null,
			id: END_OF_PAGE_RECOMMENDATION_MENU,
			label: 'Hide related pages...',
			moduleKey: 'end-of-page-recommendation-menu',
			section: 'system.content.action/primary',
			webSection: 'secondary',
		},
	];

	renderMenuItems = ({ contentId, dialogs, routesContext: { match, push } }) => (
		<WebItemLocation
			tagName="span"
			location={TOOLS_MENU_LOCATION}
			hasMultipleSections
			contentId={contentId}
			version={this.props.version ?? undefined}
			renderWhenLoading
		>
			{
				(({ loading, webItems, webSections }) => {
					const {
						contentType,
						isExternalCollaborator,
						spaceKey,
						isContentToolsForcedOpen,
						setIsContentToolsForcedOpen,
					} = this.props;

					if (loading) {
						if (this.props.allowedContentToolsItems?.length == 0) {
							return null;
						}

						const isPageSSRd = Boolean(process.env.REACT_SSR || window.__SSR_RENDERED__);

						return (
							<>
								{process.env.REACT_SSR && (
									<SSRActionLoadingSpinner
										spinnerId="more-action-loading-spinner"
										actionType="moreActionButton"
									/>
								)}
								<ContentToolsButton isDisabled={!isPageSSRd} isLoading />
							</>
						);
					}

					webItems = this.addEndofPageRecommendationToWebItems(webItems);

					const allowedWebItems = this.getAllowedWebItems(webItems);

					this.groupWebItemsBySubSection(allowedWebItems);

					const isSpaceOverview = match?.name === SPACE_OVERVIEW.name;
					const isViewPage = match?.name === VIEW_PAGE.name;
					const isHistoryPage = match?.name === CONTENT_HISTORY.name;

					const { itemSections, keyboardShortcuts } = this.createMenuItems(
						allowedWebItems,
						webSections,
						dialogs,
						isSpaceOverview,
						isViewPage,
						isHistoryPage,
					);

					/* For EP View Page when item-sections is empty do not render content tools */
					if (this.props.allowedContentToolsItems && itemSections.length == 0) {
						return null;
					}

					return (
						<ForgeContentTools
							spaceKey={spaceKey}
							contentId={contentId}
							onLoadComplete={() => {
								this.contentToolsComponentRef.current?.closeDialog();
							}}
							isDropdownMenuOpen={this.state?.isDropdownMenuOpen ?? false}
						>
							{(forgeMenuItems) => {
								let result = itemSections;
								if (forgeMenuItems.length) {
									const tooltippedForgeMenuItems = forgeMenuItems.map((f) => (
										<ForgeKeyboardShortcutVisualizer
											key={f.props.extension.id}
											module={f.props.extension}
										>
											{f}
										</ForgeKeyboardShortcutVisualizer>
									));
									result = [...itemSections, tooltippedForgeMenuItems];
								}

								const forgeKeyboardShortcuts = forgeMenuItems.map((f) => (
									<ForgeKeyboardShortcut
										key={f.props.extension.id}
										module={f.props.extension}
										action={() => {
											openMenuClick();
											simulateItemClick(f.props.extension.id);
										}}
									/>
								));
								const viewAttachmentsWebItem = webItems.find(
									(webItem) => webItem.id === VIEW_ATTACHMENTS_LINK,
								);
								const viewAttachmentsShotcutListener = viewAttachmentsWebItem ? (
									<GeneralShortcutListener
										accelerator={VIEW_ATTACHMENTS_SHORTCUT}
										listener={this.navigateToAttachments(push, viewAttachmentsWebItem.url)}
									/>
								) : null;

								const isMenuOpen =
									!!this.state?.isDropdownMenuOpen || isContentToolsForcedOpen || false;

								return (
									<>
										<ContentToolsComponent
											contentType={contentType}
											key="content-tools-component"
											contentId={contentId}
											itemSections={result}
											keyboardShortcuts={keyboardShortcuts}
											// @ts-ignore
											ref={this.contentToolsComponentRef}
											isMenuOpen={isMenuOpen}
											onOpenChange={(isOpen) => {
												if (!this.state?.nestedPopup) {
													this.setState({
														isDropdownMenuOpen: isOpen,
													});
													if (isContentToolsForcedOpen && !isOpen) {
														setIsContentToolsForcedOpen && setIsContentToolsForcedOpen(false);
													}
												}
											}}
											preloadCopyItem={LazyContentToolsItemForCopy.preload}
											preloadMoveItem={LazyContentToolsItemForMove.preload}
											spaceKey={spaceKey}
											isExternalCollaborator={isExternalCollaborator}
										/>
										{forgeKeyboardShortcuts}
										{viewAttachmentsShotcutListener}
									</>
								);
							}}
						</ForgeContentTools>
					);
				}) as WebItemLocationChildrenFnWithLoading
			}
		</WebItemLocation>
	);

	render() {
		const { contentId, onRender } = this.props;
		onRender?.();
		return (
			<FlagsProvider>
				<ErrorBoundary attribution={Attribution.BACKBONE}>
					<ExperienceStart id={contentId} name={VIEW_PAGE_CONTENT_TOOLS_EXPERIENCE} />
					<Subscribe to={[DialogsStateContainer]}>
						{(dialogs: DialogsStateContainer) => (
							<>
								<RoutesContext.Consumer>
									{(routesContext) => (
										<>
											{this.renderMenuItems({
												contentId,
												dialogs,
												routesContext,
											})}
											{this.renderResolvedInlineCommentsQueryRendererLoader(routesContext)}
										</>
									)}
								</RoutesContext.Consumer>

								<GeneralShortcutListener
									accelerator={LINK_TO_THIS_PAGE_SHORTCUT}
									listener={this.makeLinkToThisPageDialogOpener(dialogs)}
								/>
							</>
						)}
					</Subscribe>
				</ErrorBoundary>
			</FlagsProvider>
		);
	}
}

const withSpaceId = (Component: any) => {
	return (props: any) => {
		const spaceId = useSpaceId();
		return <Component spaceId={spaceId} {...props} />;
	};
};

export const ContentTools = withSpaceId(
	withSessionData(
		withExperienceTracker(
			// @ts-ignore
			withAnalyticsEvents()(withThemingState(injectIntl(ContentToolsClass))),
		),
	),
) as ComponentType<ContentToolsProps>;
